import { XIcon } from '@heroicons/react/outline'
import Countdown from 'react-countdown'
import { MiniGrid } from '../mini-grid/MiniGrid'
import { shareStatus } from '../../lib/share'
import { BaseModal } from './BaseModal'
import { LOCATIONS } from '../../constants/locations'
import { tomorrow } from '../../lib/words'

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  handleShare: () => void
  solution: string
  copiedToClipboard: boolean
}

export const LoseModal = ({
  isOpen,
  handleClose,
  guesses,
  handleShare,
  solution,
  copiedToClipboard,
}: Props) => {
  const location = LOCATIONS[solution.toLowerCase()] || []

  return (
    <BaseModal
      title="Darn, so close, yet so far"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div>
        <div className="mx-auto mt-3 flex h-10 w-10 items-center justify-center rounded-full bg-red-2 sm:h-12 sm:w-12">
          <XIcon
            className="h-5 w-5 text-red+3 sm:h-6 sm:w-6"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <p className="mb-3 text-sm text-gray-500">
            The solution was <strong>{solution}</strong>, aka {location[0]}{' '}
            located in {location[1]}, {location[2]}.
          </p>
          <div className="mt-2">
            <MiniGrid guesses={guesses} />
            <p className="mb-3 text-sm text-gray-500">
              Brush up on those airport codes and try again in{' '}
              <Countdown date={tomorrow} daysInHours={true} />!
            </p>
            <p className="text-sm text-gray-500">
              More of an experiential learner?
              <br />
              You should probably just{' '}
              <a
                className="text-royal+1 underline hover:text-royal+3"
                href={`https://www.going.com/deals?utm_source=airportle&utm_medium=website&utm_campaign=lose&utm_content=${solution}`}
                target="_blank"
                rel="noreferrer"
              >
                browse our flight deals
              </a>{' '}
              and treat yourself with a trip to <strong>{solution}</strong>.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          disabled={copiedToClipboard}
          className={`${
            copiedToClipboard
              ? 'bg-charcoal-1'
              : 'bg-orange hover:bg-orange+1 active:bg-orange+1'
          } inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm`}
          onClick={() => {
            shareStatus(guesses, true)
            handleShare()
          }}
        >
          {copiedToClipboard ? 'Game copied to clipboard' : 'Share'}
        </button>
      </div>
    </BaseModal>
  )
}
