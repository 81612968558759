type Props = {
  index: number
  size: number
  label: string
}

export const Progress = ({ index, size, label }: Props) => {
  return (
    <div className="justify-left m-1 flex">
      <div className="w-2 items-center justify-center">{index + 1}</div>
      <div className="ml-2 w-full rounded-full">
        <div
          style={{ width: `${5 + size}%` }}
          className="rounded-l-full bg-green+2 p-0.5 text-center text-xs font-medium text-blue-100"
        >
          {label}
        </div>
      </div>
    </div>
  )
}
