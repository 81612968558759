import { InformationCircleIcon } from '@heroicons/react/outline'
import { ChartBarIcon } from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { WinModal } from './components/modals/WinModal'
import { LoseModal } from './components/modals/LoseModal'
import { StatsModal } from './components/modals/StatsModal'
import { isWordInWordList, isWinningWord, solution } from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'
import Links from './Links'

function App() {
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isWinModalOpen, setIsWinModalOpen] = useState(false)
  const [isLoseModalOpen, setIsLoseModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameLost(true)
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      setIsWinModalOpen(true)
    }
  }, [isGameWon])

  useEffect(() => {
    if (isGameLost) {
      setIsLoseModalOpen(true)
    }
  }, [isGameLost])

  const onChar = (value: string) => {
    if (currentGuess.length < 3 && guesses.length < 6 && !isGameWon) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (!(currentGuess.length === 3) && !isGameLost) {
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, 2000)
    }

    if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, 2000)
    }

    const winningWord = isWinningWord(currentGuess)

    if (currentGuess.length === 3 && guesses.length < 6 && !isGameWon) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true)
      }

      if (guesses.length === 5) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsGameLost(true)
      }
    }
  }

  return (
    <div className="relative min-h-screen overflow-hidden bg-charcoal-3">
      <div className="absolute ml-20 min-h-screen min-w-full">
        <img
          className="-translate-y-px:200 absolute left-1/2 hidden -translate-x-1/2 background-airplane:block"
          alt="airplane"
          src={process.env.PUBLIC_URL + '/jet-stream.svg'}
          style={{ top: '16%' }}
          width={1400}
        />
      </div>
      <div className="z:2 relative mx-auto max-w-7xl py-8 sm:px-6 lg:px-8">
        <div className="mx-auto mb-8 flex max-w-xs items-center">
          <h1 className="flex grow items-center text-xl font-bold">
            <a
              href="https://going.com?utm_source=airportle&utm_medium=website&utm_content=logo"
              className="mr-2 font-bold underline"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={process.env.PUBLIC_URL + '/going-symbol.svg'}
                alt="Going"
                title="Going"
                height={32}
                width={32}
                className="h-8 w-8"
              />
            </a>
            Airportle
          </h1>
          <InformationCircleIcon
            className="mr-2 h-6 w-6 cursor-pointer"
            onClick={() => setIsInfoModalOpen(true)}
          />
          <ChartBarIcon
            className="h-6 w-6 cursor-pointer"
            onClick={() => setIsStatsModalOpen(true)}
          />
        </div>
        <Grid guesses={guesses} currentGuess={currentGuess} />
        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          guesses={guesses}
        />
        <WinModal
          isOpen={isWinModalOpen}
          handleClose={() => setIsWinModalOpen(false)}
          guesses={guesses}
          copiedToClipboard={copiedToClipboard}
          handleShare={() => {
            setCopiedToClipboard(true)
            return setTimeout(() => {
              setCopiedToClipboard(false)
            }, 2000)
          }}
          solution={solution}
        />
        <LoseModal
          isOpen={isLoseModalOpen}
          handleClose={() => setIsLoseModalOpen(false)}
          guesses={guesses}
          copiedToClipboard={copiedToClipboard}
          handleShare={() => {
            setCopiedToClipboard(true)
            return setTimeout(() => {
              setCopiedToClipboard(false)
            }, 2000)
          }}
          solution={solution}
        />
        <InfoModal
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
        <StatsModal
          isOpen={isStatsModalOpen}
          handleClose={() => setIsStatsModalOpen(false)}
          gameStats={stats}
        />
        <AboutModal
          isOpen={isAboutModalOpen}
          handleClose={() => setIsAboutModalOpen(false)}
        />

        <button
          type="button"
          className="mx-auto mt-8 flex select-none items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-royal+1 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => setIsAboutModalOpen(true)}
        >
          About this game
        </button>

        <Alert message="Not enough letters" isOpen={isNotEnoughLetters} />
        <Alert message="IATA code not found" isOpen={isWordNotFoundAlertOpen} />
        <Alert
          message={`You lost, the solution was ${solution}`}
          isOpen={isGameLost}
        />
      </div>
      <Links />
    </div>
  )
}

export default App
