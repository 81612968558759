import { CharStatus } from '../../lib/statuses'
import classnames from 'classnames'

type Props = {
  status: CharStatus
}

export const MiniCell = ({ status }: Props) => {
  const classes = classnames(
    'w-8 h-8 sm:w-10 sm:h-10 border-solid border-2 border-charcoal-2 flex items-center justify-center mx-0.5 text-lg font-bold rounded',
    {
      'bg-white': status === 'absent',
      'bg-green+2': status === 'correct',
      'bg-yellow+2': status === 'present',
    }
  )

  return (
    <>
      <div className={classes}></div>
    </>
  )
}
