import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="mb-6 text-sm text-gray-500">
        This is a fork of the{' '}
        <a
          href="https://github.com/hannahcode/wordle"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          open source clone
        </a>{' '}
        of the game Wordle, adapted for travel-lovers by{' '}
        <a
          href="https://www.going.com?utm_source=airportle&utm_medium=website&utm_content=about"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          Going
        </a>
        .
      </p>
      <p className="text-sm text-gray-500">
        Test your airport knowledge or{' '}
        <a
          href="https://www.nytimes.com/games/wordle/index.html"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          play the original game here
        </a>
        .
      </p>
    </BaseModal>
  )
}
