import './Links.css'

function Links() {
  return (
    <div className="Links__outer">
      <div className="Links__inner">
        <div>
          <h2>Become a cheap flight expert</h2>
          <div className="Links__list">
            <div>
              Book <a href="https://www.going.com/flights">Cheap Flights</a>{' '}
              with Going
            </div>
            <div>
              <a href="https://www.going.com/guides/how-to-find-cheap-flights">
                How to Find Cheap Flights
              </a>
            </div>
            <div>
              How to Use{' '}
              <a href="https://www.going.com/guides/how-to-use-google-flights">
                Google Flights
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Links
