export const LOCATIONS: { [index: string]: any } = {
  aal: ['Aalborg', 'Aalborg', 'Denmark'],
  abe: ['Lehigh Valley Intl.', 'Allentown, PA', 'USA'],
  abi: ['Abilene Regional', 'Abilene, TX', 'USA'],
  abq: ['Albuquerque Intl. Sunport', 'Albuquerque, NM', 'USA'],
  aby: ['Southwest Georgia Regional', 'Albany, GA', 'USA'],
  aca: ['Acapulco Intl.', 'Acapulco', 'Mexico'],
  ack: ['Nantucket Memorial', 'Nantucket, MA', 'USA'],
  act: ['Waco Municipal', 'Waco, TX', 'USA'],
  acv: ['Arcata-Eureka', 'Arcata, CA', 'USA'],
  adb: ['Izmir Adnan Menderes', 'Izmir', 'Türkiye'],
  adz: ['Gustavo Rojas Pinilla', 'San Andrés', 'Colombia'],
  aex: ['Alexandria Intl.', 'Alexandria, LA', 'USA'],
  agp: ['Malaga', 'Malaga', 'Spain'],
  ags: ['Augusta Regional', 'Augusta, GA', 'USA'],
  agu: ['Jesus Teran Peredo Intl.', 'Aguascalientes', 'Mexico'],
  akl: ['Auckland', 'Auckland', 'New Zealand'],
  alb: ['Albany Intl.', 'Albany, NY', 'USA'],
  alc: ['Alicante-Elche', 'Alicante', 'Spain'],
  alg: ['Houari Boumediene', 'Algiers', 'Algeria'],
  alo: ['Waterloo', 'Waterloo, IA', 'USA'],
  als: ['Alamosa Municipal', 'Alamosa, CO', 'USA'],
  ama: ['Rick Husband Amarillo Intl.', 'Amarillo, TX', 'USA'],
  amm: ['Queen Alia Intl.', 'Amman', 'Jordan'],
  ams: ['Amsterdam Schiphol', 'Amsterdam', 'Netherlands'],
  anc: ['Ted Stevens Anchorage Intl.', 'Anchorage, AK', 'USA'],
  anf: ['Cerro Moreno Intl.', 'Antofagasta', 'Chile'],
  anu: ['V.C. Bird Intl.', 'Antigua', 'Antigua and Barbuda'],
  aqp: ['Rodriguez Ballon Intl.', 'Arequipa', 'Peru'],
  arn: ['Stockholm Arlanda', 'Stockholm', 'Sweden'],
  art: ['Watertown', 'Watertown, NY', 'USA'],
  asu: ['Silvio Pettirossi Intl.', 'Asunción', 'Paraguay'],
  ath: ['Eleftherios Venizelos Intl.', 'Athens', 'Greece'],
  atl: ['Hartsfield-Jackson Atlanta Intl.', 'Atlanta, GA', 'USA'],
  atw: ['Appleton Intl.', 'Appleton, WI', 'USA'],
  aty: ['Watertown', 'Watertown, SD', 'USA'],
  aua: ['Reina Beatrix Intl.', 'Aruba', 'Aruba'],
  aug: ['Augusta', 'Augusta, ME', 'USA'],
  auh: ['Abu Dhabi Intl.', 'Abu Dhabi', 'United Arab Emirates'],
  aus: ['Austin-Bergstrom Intl.', 'Austin, TX', 'USA'],
  avl: ['Asheville Regional', 'Asheville, NC', 'USA'],
  avp: ['Wilkes-Barre/Scranton Intl.', 'Scranton, PA', 'USA'],
  axm: ['El Eden', 'Armenia', 'Colombia'],
  azo: ['Kalamazoo/Battle Creek Intl.', 'Kalamazoo, MI', 'USA'],
  baq: ['Ernesto Cortissoz Intl.', 'Barranquilla', 'Colombia'],
  bcn: ['Barcelona-El Prat', 'Barcelona', 'Spain'],
  bda: ['L.F. Wade Intl.', 'Bermuda', 'Bermuda'],
  bdl: ['Bradley Intl.', 'Hartford, CT', 'USA'],
  beg: ['Belgrad Nikola Tesla', 'Belgrade', 'Serbia'],
  ber: ['Berlin Brandenburg', 'Berlin', 'Germany'],
  bey: ['Beirut-Rafic Hariri Intl.', 'Beirut', 'Lebanon'],
  bff: ['Western Nebraska Regional', 'Scottsbluff, NE', 'USA'],
  bfl: ['Meadows Field', 'Bakersfield, CA', 'USA'],
  bga: ['Palonegro Intl.', 'Bucaramanga', 'Colombia'],
  bgi: ['Grantley Adams Intl.', 'Bridgetown', 'Barbados'],
  bgm: ['Greater Binghamton', 'Binghamton, NY', 'USA'],
  bgo: ['Bergen', 'Bergen', 'Norway'],
  bgr: ['Bangor Intl.', 'Bangor, ME', 'USA'],
  bhm: ['Birmingham-Shuttlesworth Intl.', 'Birmingham, AL', 'USA'],
  bhx: ['Birmingham Intl.', 'Birmingham', 'United Kingdom'],
  bil: ['Billings Logan Intl.', 'Billings, MT', 'USA'],
  bio: ['Bilbao', 'Bilbao', 'Spain'],
  bis: ['Bismarck', 'Bismarck, ND', 'USA'],
  bjv: ['Milas-Bodrum', 'Bodrum', 'Türkiye'],
  bjx: ['Del Bajio Intl.', 'León', 'Mexico'],
  bkk: ['Suvarnabhumi', 'Bangkok', 'Thailand'],
  bli: ['Bellingham Intl.', 'Bellingham, WA', 'USA'],
  bll: ['Billund', 'Billund', 'Denmark'],
  blq: ['Guglielmo Marconi', 'Bologna', 'Italy'],
  bma: ['Bromma', 'Stockholm', 'Sweden'],
  bmi: ['Central Illinois Regional', 'Bloomington, IL', 'USA'],
  bna: ['Nashville Intl.', 'Nashville, TN', 'USA'],
  bne: ['Brisbane', 'Brisbane', 'Australia'],
  bog: ['El Dorado Intl.', 'Bogotá', 'Colombia'],
  boi: ['Boise Air Terminal (Gowen Field)', 'Boise, ID', 'USA'],
  bom: ['Chhatrapati Shivaji Intl.', 'Mumbai', 'India'],
  bos: ['Logan Intl.', 'Boston, MA', 'USA'],
  bpt: ['Jefferson County', 'Beaumont, TX', 'USA'],
  bqk: ['Glynco Jetport', 'Brunswick, GA', 'USA'],
  bqn: ['Rafael Hernandez', 'Aguadilla', 'Puerto Rico'],
  brl: ['Burlington', 'Burlington, IA', 'USA'],
  bro: ['South Padre Island Intl.', 'Brownsville, TX', 'USA'],
  bru: ['Brussels', 'Brussels', 'Belgium'],
  bsb: ['Brasilia Intl.', 'Brasília', 'Brazil'],
  bsl: ['Euro Swiss', 'Basel', 'Switzerland'],
  btm: ['Butte', 'Butte, MT', 'USA'],
  btr: ['Baton Rouge Metropolitan', 'Baton Rouge, LA', 'USA'],
  btv: ['Burlington Intl.', 'Burlington, VT', 'USA'],
  bud: ['Budapest Ferenc Liszt Intl.', 'Budapest', 'Hungary'],
  buf: ['Buffalo Niagara Intl.', 'Buffalo, NY', 'USA'],
  bur: ['Bob Hope', 'Burbank, CA', 'USA'],
  bwi: ['Baltimore/Wash Intl. Thurgood Marshall', 'Baltimore, MD', 'USA'],
  bze: ['Philip S.W. Goldson Intl.', 'Belize City', 'Belize'],
  bzn: ['Bozeman Yellowstone Intl.', 'Bozeman, MT', 'USA'],
  cae: ['Columbia Metropolitan', 'Columbia, SC', 'USA'],
  cai: ['Cairo Intl.', 'Cairo', 'Egypt'],
  cak: ['Akron-Canton', 'Akron, OH', 'USA'],
  ccr: ['Buchanan Field', 'Concord, CA', 'USA'],
  cdg: ['Charles de Gaulle', 'Paris', 'France'],
  cec: ['Del Norte County Regional', 'Crescent City, CA', 'USA'],
  cen: ['Ciudad Obregon', 'Ciudad Obregon', 'Mexico'],
  cfn: ['Donegal', 'Donegal', 'Ireland'],
  cgh: ['Sao Paulo/Congonhas', 'São Paulo', 'Brazil'],
  cgi: ['Cape Girardeau', 'Cape Girardeau, MO', 'USA'],
  cgk: ['Soekarno-Hatta Intl.', 'Jakarta', 'Indonesia'],
  cha: ['Chattanooga', 'Chattanooga, TN', 'USA'],
  cho: ['Charlottesville Albemarle', 'Charlottesville, VA', 'USA'],
  chs: ['Charleston Intl.', 'Charleston, SC', 'USA'],
  cid: ['The Eastern Iowa', 'Cedar Rapids, IA', 'USA'],
  cix: ['Cornel Ruiz', 'Chiclayo', 'Peru'],
  cjs: ['Abraham Gonzalez Intl.', 'Ciudad Juarez', 'Mexico'],
  ckb: ['Benedum', 'Clarksburg, WV', 'USA'],
  cle: ['Cleveland Hopkins Intl.', 'Cleveland, OH', 'USA'],
  clj: ['Cluj-Napoca Intl.', 'Cluj-Napoca', 'Romania'],
  cll: ['Easterwood', 'College Station, TX', 'USA'],
  clo: ['Alfonso B. Aragon', 'Cali', 'Colombia'],
  clt: ['Charlotte Douglas Intl.', 'Charlotte, NC', 'USA'],
  cme: ['Ciudad Del Carmen', 'Ciudad Del Carmen', 'Mexico'],
  cmh: ['John Glenn Columbus Intl.', 'Columbus, OH', 'USA'],
  cmn: ['Mohamed V Intl.', 'Casablanca', 'Morocco'],
  cmx: ['Houghton County', 'Hancock, MI', 'USA'],
  cnf: ['Tancredo Neves Intl.', 'Belo Horizonte', 'Brazil'],
  cnm: ['Cavern City Air Terminal', 'Carlsbad, NM', 'USA'],
  cny: ['Canyonlands Field', 'Moab, UT', 'USA'],
  cod: ['Yellowstone Regional', 'Cody, WY', 'USA'],
  cos: ['Colorado Springs Municipal', 'Colorado Springs, CO', 'USA'],
  cou: ['Columbia Regional', 'Columbia, MO', 'USA'],
  cpe: ['Campeche Intl.', 'Campeche', 'Mexico'],
  cph: ['Copenhagen', 'Copenhagen', 'Denmark'],
  cpr: ['Casper/Natrona County Intl.', 'Casper, WY', 'USA'],
  cpt: ['Cape Town Intl.', 'Cape Town', 'South Africa'],
  crp: ['Corpus Christi Intl.', 'Corpus Christi, TX', 'USA'],
  crw: ['Yeager', 'Charleston, WV', 'USA'],
  csg: ['Columbus', 'Columbus, GA', 'USA'],
  cta: ['Catania-Fontanarossa', 'Catania', 'Italy'],
  ctg: ['Rafael Nunez Intl.', 'Cartagena', 'Colombia'],
  ctm: ['Chetumal', 'Chetumal', 'Mexico'],
  cuc: ['Camilo Daza Intl.', 'Cucuta', 'Colombia'],
  cul: ['Culiacan Intl.', 'Culiacan', 'Mexico'],
  cun: ['Cancun Intl.', 'Cancún', 'Mexico'],
  cur: ['Hato Intl.', 'Curaçao', 'Curaçao'],
  cuu: ['Gen Fierro Villalobos', 'Chihuahua', 'Mexico'],
  cuz: ['Alejandro Velasco Astete Intl.', 'Cuzco', 'Peru'],
  cvg: ['Cincinnati/Northern Kentucky', 'Cincinnati, OH', 'USA'],
  cwa: ['Central Wisconsin', 'Wausau, WI', 'USA'],
  cwb: ['Afonso Pena Intl.', 'Curitiba', 'Brazil'],
  cys: ['Cheyenne Regional', 'Cheyenne, WY', 'USA'],
  czm: ['Cozumel', 'Cozumel', 'Mexico'],
  dab: ['Daytona Beach Intl.', 'Daytona Beach, FL', 'USA'],
  dal: ['Dallas Love Field', 'Dallas, TX', 'USA'],
  day: ['James M. Cox Dayton Intl.', 'Dayton, OH', 'USA'],
  dbq: ['Dubuque Municipal', 'Dubuque, IA', 'USA'],
  dbv: ['Dubrovnik', 'Dubrovnik', 'Croatia'],
  dca: ['Ronald Reagan National', 'Washington DC, DC', 'USA'],
  ddc: ['Dodge City Regional', 'Dodge City, KS', 'USA'],
  dec: ['Decatur', 'Decatur, IL', 'USA'],
  del: ['Indira Gandhi Intl.', 'New Delhi', 'India'],
  den: ['Denver Intl.', 'Denver, CO', 'USA'],
  dfw: ['Dallas/Fort Worth Intl.', 'Dallas, TX', 'USA'],
  dgo: ['Guadalupe Victoria', 'Durango', 'Mexico'],
  dik: ['Dickinson Regional', 'Dickinson, ND', 'USA'],
  dlh: ['Duluth Intl.', 'Duluth, MN', 'USA'],
  dme: ['Moscow Domodedovo', 'Moscow', 'Russia'],
  dmm: ['King Fahd Intl.', 'Dammam', 'Saudi Arabia'],
  dom: ['Melville Hall', 'Dominica', 'Dominica'],
  dps: ['Ngurah Rai Intl.', 'Bali', 'Indonesia'],
  dro: ['Durango-La Plata County', 'Durango, CO', 'USA'],
  drt: ['Del Rio Intl.', 'Del Rio, TX', 'USA'],
  dsm: ['Des Moines Intl.', 'Des Moines, IA', 'USA'],
  dtw: ['Detroit Metropolitan Wayne County', 'Detroit, MI', 'USA'],
  dub: ['Dublin', 'Dublin', 'Ireland'],
  dus: ['Dusseldorf Intl.', 'Düsseldorf', 'Germany'],
  dxb: ['Dubai', 'Dubai', 'United Arab Emirates'],
  ear: ['Kearney Regional', 'Kearney, NE', 'USA'],
  eas: ['San Sebastian', 'San Sebastián', 'Spain'],
  eau: ['Eau Claire', 'Eau Claire, WI', 'USA'],
  ebb: ['Entebbe Intl.', 'Entebbe', 'Uganda'],
  ecp: ['Northwest Florida Beaches Intl.', 'Panama City, FL', 'USA'],
  edi: ['Edinburgh', 'Edinburgh', 'United Kingdom'],
  eis: ['Terrance B. Lettsome Intl.', 'Tortola', 'British Virgin Islands'],
  eld: ['South Arkansas Regional', 'El Dorado, AR', 'USA'],
  elh: ['North Eleuthera Intl.', 'North Eleuthera', 'Bahamas'],
  elm: ['Elmira Corning Regional', 'Elmira, NY', 'USA'],
  elp: ['El Paso Intl.', 'El Paso, TX', 'USA'],
  eri: ['Erie Intl.', 'Erie, PA', 'USA'],
  esb: ['Esenboga Intl.', 'Ankara', 'Türkiye'],
  eug: ['Mahlon Sweet Field', 'Eugene, OR', 'USA'],
  evn: ['Zvartnots Intl.', 'Yerevan', 'Armenia'],
  evv: ['Evansville Regional', 'Evansville, IN', 'USA'],
  ewn: ['Coastal Carolina Regional', 'New Bern, NC', 'USA'],
  ewr: ['Newark Liberty Intl.', 'Newark, NJ', 'USA'],
  eyw: ['Key West Intl.', 'Key West, FL', 'USA'],
  eze: ['Ministro Pistarini', 'Buenos Aires', 'Argentina'],
  fae: ['Vagar', 'Sørvágur', 'Faroe Islands'],
  fai: ['Fairbanks Intl.', 'Fairbanks, AK', 'USA'],
  fao: ['Faro', 'Faro', 'Portugal'],
  far: ['Hector Intl.', 'Fargo, ND', 'USA'],
  fat: ['Fresno Yosemite Intl.', 'Fresno, CA', 'USA'],
  fay: ['Fayetteville Regional', 'Fayetteville, NC', 'USA'],
  fca: ['Glacier Park Intl.', 'Kalispell, MT', 'USA'],
  fco: ['Leonardo da Vinci-Fiumicino', 'Rome', 'Italy'],
  fll: ['Fort Lauderdale-Hollywood Intl.', 'Fort Lauderdale, FL', 'USA'],
  flo: ['Florence', 'Florence, SC', 'USA'],
  flr: ['Firenze-Peretola', 'Florence', 'Italy'],
  fnc: ['Madeira', 'Funchal', 'Portugal'],
  fnt: ['Bishop Intl.', 'Flint, MI', 'USA'],
  fod: ['Fort Dodge', 'Fort Dodge, IA', 'USA'],
  for: ['Pinto Martins', 'Fortaleza', 'Brazil'],
  fra: ['Frankfurt', 'Frankfurt', 'Germany'],
  fsd: ['Joe Foss Field', 'Sioux Falls, SD', 'USA'],
  fue: ['Fuerteventura', 'Fuerteventura', 'Spain'],
  fwa: ['Fort Wayne Intl.', 'Fort Wayne, IN', 'USA'],
  gcc: ['Campbell County', 'Gillette , WY', 'USA'],
  gck: ['Garden City Municipal', 'Garden City, KS', 'USA'],
  gcm: ['Owen Roberts Intl.', 'Grand Cayman', 'Cayman Islands'],
  gdl: ['Miguel Hidalgo y Costilla Intl.', 'Guadalajara', 'Mexico'],
  gdn: ['Gdansk Lech Walesa', 'Gdansk', 'Poland'],
  geg: ['Spokane Intl.', 'Spokane, WA', 'USA'],
  geo: ['Cheddi Jagan Intl.', 'Georgetown', 'Guyana'],
  ggt: ['Exuma Intl.', 'George Town', 'Bahamas'],
  gig: ['Galeao Antonio Carlos Jobim Intl.', 'Rio de Janeiro', 'Brazil'],
  gjt: ['Grand Junction Regional', 'Grand Junction, CO', 'USA'],
  gla: ['Glasgow Intl.', 'Glasgow', 'United Kingdom'],
  glh: ['Greenville', 'Greenville, MS', 'USA'],
  gnd: ['Maurice Bishop Intl.', 'Grenada', 'Grenada'],
  gnv: ['Gainesville Regional', 'Gainesville, FL', 'USA'],
  got: ['Goteborg Landvetter', 'Gothenburg', 'Sweden'],
  gpt: ['Gulfport-Biloxi Intl.', 'Gulfport, MS', 'USA'],
  grb: ['Austin Straubel Intl.', 'Green Bay, WI', 'USA'],
  gri: ['Grand Island', 'Grand Island, NE', 'USA'],
  grk: ['Robert Gray Army Airfield', 'Killeen-Fort Hood, TX', 'USA'],
  grr: ['Gerald R. Ford Intl.', 'Grand Rapids, MI', 'USA'],
  gru: ['Sao Paulo-Guarulhos Intl.', 'São Paulo', 'Brazil'],
  grx: ['Granada', 'Granada', 'Spain'],
  gso: ['Piedmont Triad Intl.', 'Greensboro, NC', 'USA'],
  gsp: ['Greenville-Spartanburg Intl.', 'Greenville, SC', 'USA'],
  gtf: ['Great Falls Intl.', 'Great Falls, MT', 'USA'],
  gua: ['La Aurora Intl.', 'Guatemala City', 'Guatemala'],
  gva: ['Geneve', 'Geneva', 'Switzerland'],
  gyd: ['Heydar Aliyev Intl.', 'Baku', 'Azerbaijan'],
  gye: ['Jose Joaquin de Olmedo', 'Guayaquil', 'Ecuador'],
  haj: ['Hannover', 'Hanover', 'Germany'],
  ham: ['Hamburg', 'Hamburg', 'Germany'],
  han: ['Noi Bai Intl.', 'Hanoi', 'Vietnam'],
  hav: ['Jose Marti Intl.', 'Havana', 'Cuba'],
  hbe: ['Borg El Arab', 'Alexandria', 'Egypt'],
  hdn: ['Yampa Valley', 'Hayden, CO', 'USA'],
  hel: ['Helsinki-Vantaa', 'Helsinki', 'Finland'],
  hhh: ['Hilton Head', 'Hilton Head, SC', 'USA'],
  hkt: ['Phuket Intl.', 'Phuket', 'Thailand'],
  hln: ['Helena Regional', 'Helena, MT', 'USA'],
  hmo: ['Gen Pesqueira Garcia', 'Hermosillo', 'Mexico'],
  hnd: ['Haneda', 'Tokyo', 'Japan'],
  hnl: ['Daniel K. Inouye Intl.', 'Honolulu, HI', 'USA'],
  hob: ['Lea County', 'Hobbs, NM', 'USA'],
  hot: ['Memorial Field', 'Hot Springs, AR', 'USA'],
  hou: ['William P. Hobby', 'Houston, TX', 'USA'],
  hpn: ['Westchester County', 'Westchester County, NY', 'USA'],
  hre: ['RG Mugabe Intl.', 'Harare', 'Zimbabwe'],
  hrg: ['Hurghada Intl.', 'Hurghada', 'Egypt'],
  hrl: ['Valley Intl.', 'Harlingen, TX', 'USA'],
  hsv: ['Huntsville Intl. - Carl T. Jones Field', 'Huntsville, AL', 'USA'],
  hux: ['Huatulco', 'Huatulco', 'Mexico'],
  hvn: ['New Haven', 'New Haven, CT', 'USA'],
  hys: ['Hays Municipal', 'Hays, KS', 'USA'],
  iad: ['Washington Dulles Intl.', 'Washington DC, DC', 'USA'],
  iah: ['George Bush Intercontinental', 'Houston, TX', 'USA'],
  ibe: ['Ibague', 'Ibague', 'Colombia'],
  ibz: ['Ibiza', 'Ibiza', 'Spain'],
  icn: ['Incheon Intl.', 'Seoul', 'South Korea'],
  ict: ['Wichita Dwight D. Eisenhower National', 'Wichita, KS', 'USA'],
  ida: ['Idaho Falls Regional', 'Idaho Falls, ID', 'USA'],
  ifn: ['Isfahan Intl.', 'Isfahan', 'Iran'],
  ika: ['Imam Khomeini Intl.', 'Tehran', 'Iran'],
  ilm: ['Wilmington Intl.', 'Wilmington, NC', 'USA'],
  ind: ['Indianapolis Intl.', 'Indianapolis, IN', 'USA'],
  inv: ['Inverness', 'Inverness', 'United Kingdom'],
  iqq: ['Diego Aracena Intl.', 'Iquique', 'Chile'],
  iqt: ['C.F. Secada Vignetta Intl.', 'Iquitos', 'Peru'],
  isp: ['Long Island MacArthur', 'Islip, NY', 'USA'],
  ist: ['Istanbul Ataturk', 'Istanbul', 'Türkiye'],
  ith: ['Ithaca Tompkins Regional', 'Ithaca, NY', 'USA'],
  itm: ['Itami', 'Osaka', 'Japan'],
  ito: ['Hilo Intl.', 'Hilo, HI', 'USA'],
  jac: ['Jackson Hole', 'Jackson Hole, WY', 'USA'],
  jan: ['Jackson-Evers Intl.', 'Jackson, MS', 'USA'],
  jax: ['Jacksonville Intl.', 'Jacksonville, FL', 'USA'],
  jer: ['Jersey', 'Jersey', 'Jersey'],
  jfk: ['John F. Kennedy Intl.', 'New York City, NY', 'USA'],
  jln: ['Joplin', 'Joplin, MO', 'USA'],
  jms: ['Jamestown', 'Jamestown, ND', 'USA'],
  jnb: ['O.R. Tambo Intl.', 'Johannesburg', 'South Africa'],
  jnu: ['Juneau Intl.', 'Juneau, AK', 'USA'],
  jst: ['Cambria County', 'Johnstown, PA', 'USA'],
  kbp: ['Boryspil Intl.', 'Kyiv', 'Ukraine'],
  kef: ['Keflavik Intl.', 'Reykjavík', 'Iceland'],
  kgl: ['Kigali Intl.', 'Kigali', 'Rwanda'],
  kin: ['Norman Manley Intl.', 'Kingston', 'Jamaica'],
  kiv: ['Chisinau Intl.', 'Chișinău', 'Moldova'],
  kix: ['Kansai Intl.', 'Osaka', 'Japan'],
  koa: ['Kona Intl. at Keahole', 'Kailua-Kona, HI', 'USA'],
  krk: ['J. Paul II Intl. Krakow-Balice', 'Krakow', 'Poland'],
  krs: ['Kristiansand', 'Kristiansand', 'Norway'],
  ksc: ['Barca', 'Košice', 'Slovakia'],
  ktm: ['Tribhuvan Intl.', 'Kathmandu', 'Nepal'],
  ktn: ['Ketchikan Intl.', 'Ketchikan, AK', 'USA'],
  ktw: ['Katowice Intl.', 'Katowice', 'Poland'],
  kul: ['Kuala Lumpur Intl.', 'Kuala Lumpur', 'Malaysia'],
  kzn: ['Kazan Intl.', 'Kazan', 'Russia'],
  lan: ['Lansing Capital Region Intl.', 'Lansing, MI', 'USA'],
  lap: ['Leon', 'La Paz', 'Mexico'],
  lar: ['General Brees Field', 'Laramie, WY', 'USA'],
  las: ['McCarran Intl.', 'Las Vegas, NV', 'USA'],
  law: ['Lawton-Fort Sill Regional', 'Lawton, OK', 'USA'],
  lax: ['Los Angeles Intl.', 'Los Angeles, CA', 'USA'],
  lbb: ['Lubbock Preston Smith Intl.', 'Lubbock, TX', 'USA'],
  lbf: ['North Platte Regional (Lee Bird Field)', 'North Platte, NE', 'USA'],
  lbl: ['Mid-America Regional', 'Liberal, KS', 'USA'],
  lcg: ['A Coruna', 'La Coruna', 'Spain'],
  lch: ['Lake Charles Regional', 'Lake Charles, LA', 'USA'],
  leb: ['Lebanon Municipal', 'Lebanon, NH', 'USA'],
  led: ['Pulkovo', 'St. Petersburg', 'Russia'],
  lei: ['Almeria', 'Almeria', 'Spain'],
  lex: ['Blue Grass', 'Lexington, KY', 'USA'],
  lft: ['Lafayette Regional', 'Lafayette, LA', 'USA'],
  lga: ['LaGuardia', 'New York City, NY', 'USA'],
  lgb: ['Long Beach', 'Long Beach, CA', 'USA'],
  lgw: ['London Gatwick', 'London', 'United Kingdom'],
  lhr: ['London Heathrow', 'London', 'United Kingdom'],
  lih: ['Lihue', 'Lihue, HI', 'USA'],
  lim: ['Jorge Chavez Intl.', 'Lima', 'Peru'],
  lin: ['Milano Linate', 'Milan', 'Italy'],
  lir: ['Liberia', 'Liberia', 'Costa Rica'],
  lis: ['Lisbon Portela', 'Lisbon', 'Portugal'],
  lit: ['Bill and Hillary Clinton National', 'Little Rock, AR', 'USA'],
  lju: ['Ljubljana Joze Pucnik', 'Ljubljana', 'Slovenia'],
  llw: ['Lilongwe Intl.', 'Lilongwe', 'Malawi'],
  lmm: ['Federal', 'Los Mochis', 'Mexico'],
  lnk: ['Lincoln', 'Lincoln, NE', 'USA'],
  lpa: ['Gran Canaria', 'Las Palmas', 'Spain'],
  lpi: ['Linkoping City', 'Linköping', 'Sweden'],
  lrd: ['Laredo Intl.', 'Laredo, TX', 'USA'],
  lsc: ['La Florida', 'La Serena', 'Chile'],
  lse: ['La Crosse Regional', 'La Crosse, WI', 'USA'],
  lto: ['Loreto', 'Loreto', 'Mexico'],
  lux: ['Luxembourg', 'Luxembourg', 'Luxembourg'],
  lwb: ['Greenbrier Valley', 'Lewisburg, WV', 'USA'],
  lws: ['Lewiston-Nez Perce County Regional', 'Lewiston, ID', 'USA'],
  lyh: ['Preston-Glenn Field', 'Lynchburg, VA', 'USA'],
  lys: ['Lyon Saint-Exupery Intl.', 'Lyon', 'France'],
  maa: ['Chennai', 'Chennai', 'India'],
  mad: ['Adolfo Suarez Madrid-Barajas', 'Madrid', 'Spain'],
  maf: ['Midland Intl.', 'Midland, TX', 'USA'],
  mah: ['Menorca', 'Menorca', 'Spain'],
  mam: ['Matamoros', 'Matamoros', 'Mexico'],
  man: ['Manchester', 'Manchester', 'United Kingdom'],
  mao: ['Eduardo Gomes Intl.', 'Manaus', 'Brazil'],
  mbj: ['Sangster Intl.', 'Montego Bay', 'Jamaica'],
  mbl: ['Blacker', 'Manistee, MI', 'USA'],
  mbs: ['MBS Intl.', 'Saginaw, MI', 'USA'],
  mci: ['Kansas City Intl.', 'Kansas City, MO', 'USA'],
  mcn: ['Middle Georgia Regional', 'Macon, GA', 'USA'],
  mco: ['Orlando Intl.', 'Orlando, FL', 'USA'],
  mct: ['Muscat Intl.', 'Muscat', 'Oman'],
  mcw: ['Mason City', 'Mason City, IA', 'USA'],
  mde: ['Jose Maria Cordova Intl.', 'Medellín', 'Colombia'],
  mdt: ['Harrisburg Intl.', 'Harrisburg, PA', 'USA'],
  mdw: ['Chicago Midway Intl.', 'Chicago, IL', 'USA'],
  mei: ['Key Field', 'Meridian, MS', 'USA'],
  mem: ['Memphis Intl.', 'Memphis, TN', 'USA'],
  mex: ['Benito Juarez Intl.', 'Mexico City', 'Mexico'],
  mfe: ['McAllen Intl.', 'McAllen, TX', 'USA'],
  mfr: ['Rogue Valley Intl.-Medford', 'Medford, OR', 'USA'],
  mga: ['Augusto C Sandino Intl.', 'Managua', 'Nicaragua'],
  mgm: ['Montgomery Regional -Dannelly Field', 'Montgomery, AL', 'USA'],
  mhd: ['Shahid Hashemi Nejad', 'Mashhad', 'Iran'],
  mhh: ['Marsh Harbour Intl.', 'Marsh Harbour', 'Bahamas'],
  mhk: ['Manhattan Municipal', 'Manhattan, KS', 'USA'],
  mht: ['Manchester Boston Regional', 'Manchester, NH', 'USA'],
  mia: ['Miami Intl.', 'Miami, FL', 'USA'],
  mid: ['Manuel Crescencio Rejon Intl.', 'Mérida', 'Mexico'],
  mke: ['General Mitchell Intl.', 'Milwaukee, WI', 'USA'],
  mkg: ['Muskegon', 'Muskegon, MI', 'USA'],
  mla: ['Malta Intl.', 'Malta', 'Malta'],
  mlb: ['Orlando Melbourne Intl.', 'Melbourne, FL', 'USA'],
  mle: ['Velana Intl.', 'Malé', 'Maldives'],
  mli: ['Quad City Intl.', 'Moline, IL', 'USA'],
  mlm: ['Morelia', 'Morelia', 'Mexico'],
  mnl: ['Ninoy Aquino Intl.', 'Manila', 'Philippines'],
  mob: ['Mobile Regional', 'Mobile, AL', 'USA'],
  mot: ['Minot Intl.', 'Minot, ND', 'USA'],
  mrs: ['Marseille Provence', 'Marseille', 'France'],
  mry: ['Monterey Regional', 'Monterey, CA', 'USA'],
  msn: ['Dane County Regional', 'Madison, WI', 'USA'],
  mso: ['Missoula Intl.', 'Missoula, MT', 'USA'],
  msp: ['Minneapolis-St. Paul Intl.', 'Minneapolis, MN', 'USA'],
  msy: ['Louis Armstrong New Orleans Intl.', 'New Orleans, LA', 'USA'],
  mtj: ['Montrose Regional', 'Montrose, CO', 'USA'],
  mtr: ['Los Garzones', 'Monteria', 'Colombia'],
  mtt: ['Minatitlan', 'Minatitlan', 'Mexico'],
  mty: ['Gen Mariano Escobedo', 'Monterrey', 'Mexico'],
  muc: ['Franz Josef Strauss', 'Munich', 'Germany'],
  mvd: ['Carrasco Intl.', 'Montevideo', 'Uruguay'],
  mvy: ["Martha's Vineyard", "Martha's Vineyard, MA", 'USA'],
  mwa: ['Williamson County', 'Marion, IL', 'USA'],
  mxl: ['Mexicali', 'Mexicali', 'Mexico'],
  mxp: ['Milano Malpensa', 'Milan', 'Italy'],
  myr: ['Myrtle Beach Intl.', 'Myrtle Beach, SC', 'USA'],
  mzt: ['Mazatlan Intl.', 'Mazatlán', 'Mexico'],
  nan: ['Nadi Intl.', 'Nadi', 'Fiji'],
  nap: ['Naples Intl.', 'Naples', 'Italy'],
  nas: ['Lynden Pindling Intl.', 'Nassau', 'Bahamas'],
  nbo: ['Jomo Kenyatta Intl.', 'Nairobi', 'Kenya'],
  nce: ["Cote D'Azur", 'Nice', 'France'],
  ncl: ['Newcastle Intl.', 'Newcastle', 'United Kingdom'],
  nld: ['Quetzalcoatl Intl.', 'Nuevo Laredo', 'Mexico'],
  nrt: ['Narita Intl.', 'Tokyo', 'Japan'],
  nue: ['Nuremberg', 'Nuremberg', 'Germany'],
  nva: ['Benito Salas', 'Neiva', 'Colombia'],
  oaj: ['Albert J Ellis', 'Jacksonville, NC', 'USA'],
  oak: ['Metropolitan Oakland Intl.', 'Oakland, CA', 'USA'],
  oax: ['Xoxocotlan', 'Oaxaca', 'Mexico'],
  ogg: ['Kahului', 'Kahului, HI', 'USA'],
  ogs: ['Ogdensburg', 'Ogdensburg, NY', 'USA'],
  okc: ['Will Rogers World', 'Oklahoma City, OK', 'USA'],
  oma: ['Eppley Airfield', 'Omaha, NE', 'USA'],
  ont: ['Ontario Intl.', 'Ontario, CA', 'USA'],
  opo: ['Porto', 'Porto', 'Portugal'],
  ord: ["O'Hare Intl.", 'Chicago, IL', 'USA'],
  orf: ['Norfolk Intl.', 'Norfolk, VA', 'USA'],
  orh: ['Worcester Regional', 'Worcester, MA', 'USA'],
  ork: ['Cork', 'Cork', 'Ireland'],
  ory: ['Paris Orly', 'Paris', 'France'],
  osl: ['Oslo Gardermoen', 'Oslo', 'Norway'],
  otp: ['Bucharest Henri Coanda Intl.', 'Bucharest', 'Romania'],
  oul: ['Oulu', 'Oulu', 'Finland'],
  ovd: ['Asturias', 'Asturias', 'Spain'],
  owb: ['Daviess County', 'Owensboro, KY', 'USA'],
  pae: ['Snohomish County', 'Everett, WA', 'USA'],
  pah: ['Barkley Regional', 'Paducah, KY', 'USA'],
  pap: ['Toussaint Louverture Intl.', 'Port-au-Prince', 'Haiti'],
  pbc: ['Huejotsingo', 'Puebla', 'Mexico'],
  pbg: ['Plattsburgh Intl.', 'Plattsburgh, NY', 'USA'],
  pbi: ['Palm Beach Intl.', 'West Palm Beach, FL', 'USA'],
  pbm: ['Zanderij Intl.', 'Paramaribo', 'Suriname'],
  pdl: ['Joao Paulo II', 'Ponta Delgada', 'Portugal'],
  pdx: ['Portland Intl.', 'Portland, OR', 'USA'],
  pei: ['Matecana Intl.', 'Pereira', 'Colombia'],
  pga: ['Page', 'Page, AZ', 'USA'],
  pgv: ['Pitt-Greenville', 'Greenville, NC', 'USA'],
  phf: ['Newport News/Williamsburg Intl.', 'Newport News, VA', 'USA'],
  phl: ['Philadelphia Intl.', 'Philadelphia, PA', 'USA'],
  phx: ['Phoenix Sky Harbor Intl.', 'Phoenix, AZ', 'USA'],
  pia: ['Peoria Intl.', 'Peoria, IL', 'USA'],
  pib: ['Hattiesburg-Laurel Regional', 'Laurel, MS', 'USA'],
  pit: ['Pittsburgh Intl.', 'Pittsburgh, PA', 'USA'],
  piu: ['Cap. FAP Guillermo Concha Iberico Intl.', 'Piura', 'Peru'],
  plq: ['Palanga Intl.', 'Palanga', 'Lithuania'],
  pls: ['Providenciales Intl.', 'Providenciales', 'Turks and Caicos'],
  pmi: ['Palma de Mallorca', 'Palma de Mallorca', 'Spain'],
  pna: ['Pamplona', 'Pamplona', 'Spain'],
  pnh: ['Phnom Penh Intl.', 'Phnom Penh', 'Cambodia'],
  pns: ['Pensacola Intl.', 'Pensacola, FL', 'USA'],
  poa: ['Salgado Filho Intl.', 'Porto Alegre', 'Brazil'],
  pop: ['La Union', 'Puerto Plata', 'Dominican Republic'],
  pos: ['Piarco Intl.', 'Port of Spain', 'Trinidad and Tobago'],
  poz: ['Poznan', 'Poznan', 'Poland'],
  ppt: ["Tahiti Faa'a", 'Papeete', 'French Polynesia'],
  pqi: ['Presque Isle Municipal', 'Presque Isle, ME', 'USA'],
  prc: ['Ernest A. Love Field', 'Prescott, AZ', 'USA'],
  prg: ['Vaclav Havel Prague', 'Prague', 'Czechia'],
  prn: ['Pristina Intl.', 'Pristina', 'Kosovo'],
  psa: ['Pisa Intl.', 'Pisa', 'Italy'],
  psc: ['Tri-Cities', 'Pasco, WA', 'USA'],
  pso: ['Cano', 'Pasto', 'Colombia'],
  psp: ['Palm Springs Intl.', 'Palm Springs, CA', 'USA'],
  ptp: ['Pointe-a-Pitre Le Raizet', 'Pointe-à-Pitre', 'Guadeloupe'],
  pty: ['Tocumen Intl.', 'Panama City', 'Panama'],
  pub: ['Pueblo Memorial', 'Pueblo, CO', 'USA'],
  puj: ['Punta Cana Intl.', 'Punta Cana', 'Dominican Republic'],
  pvd: ['Theodore Francis Green Memorial State', 'Providence, RI', 'USA'],
  pvr: ['Gustavo Diaz Ordaz Intl.', 'Puerto Vallarta', 'Mexico'],
  pwm: ['Portland Intl. Jetport', 'Portland, ME', 'USA'],
  pxm: ['Puerto Escondido', 'Puerto Escondido', 'Mexico'],
  pxo: ['Porto Santo', 'Porto Santo', 'Portugal'],
  qro: ['Queretaro Intercontinental', 'Santiago de Querétaro', 'Mexico'],
  rak: ['Menara', 'Marrakesh', 'Morocco'],
  rap: ['Rapid City Regional', 'Rapid City, SD', 'USA'],
  rdd: ['Redding Municipal', 'Redding, CA', 'USA'],
  rdm: ['Roberts Field', 'Redmond, OR', 'USA'],
  rdu: ['Raleigh-Durham Intl.', 'Raleigh, NC', 'USA'],
  rec: ['Guararapes-Gilberto Freyre Intl.', 'Recife', 'Brazil'],
  rex: ['Gen Lucio Blanco Intl.', 'Reynosa', 'Mexico'],
  ric: ['Richmond Intl. (Byrd Field)', 'Richmond, VA', 'USA'],
  riw: ['Riverton', 'Riverton, WY', 'USA'],
  rix: ['Riga Intl.', 'Riga', 'Latvia'],
  rks: ['Rock Springs-Sweetwater County', 'Rock Springs, WY', 'USA'],
  rno: ['Reno/Tahoe Intl.', 'Reno, NV', 'USA'],
  roa: ['Roanoke-Blacksburg Regional', 'Roanoke, VA', 'USA'],
  roc: ['Greater Rochester Intl.', 'Rochester, NY', 'USA'],
  rst: ['Rochester Intl.', 'Rochester, MN', 'USA'],
  rsw: ['Southwest Florida Intl.', 'Fort Myers, FL', 'USA'],
  rtb: ['Roatán', 'Roatán', 'Honduras'],
  rut: ['Rutland', 'Rutland, VT', 'USA'],
  rze: ['Rzeszow Intl.', 'Rzeszow', 'Poland'],
  saf: ['Santa Fe Municipal', 'Santa Fe, NM', 'USA'],
  sal: ['El Salvador Intl.', 'San Salvador', 'El Salvador'],
  san: ['San Diego Intl.', 'San Diego, CA', 'USA'],
  sap: ['Ramon Villeda Morales Intl.', 'San Pedro Sula', 'Honduras'],
  sat: ['San Antonio Intl.', 'San Antonio, TX', 'USA'],
  sav: ['Savannah/Hilton Head', 'Savannah, GA', 'USA'],
  sba: ['Santa Barbara Municipal', 'Santa Barbara, CA', 'USA'],
  sbn: ['South Bend Regional', 'South Bend, IN', 'USA'],
  sbp: ['San Luis Obispo County Regional', 'San Luis Obispo, CA', 'USA'],
  sby: ['Wicomico Regional', 'Ocean City, MD', 'USA'],
  sce: ['University Park', 'State College, PA', 'USA'],
  scl: ['Arturo Merino Benitez', 'Santiago', 'Chile'],
  scq: ['Santiago de Compostela', 'Santiago de Compostela', 'Spain'],
  sdf: ['Louisville Intl. -Standiford Field', 'Louisville, KY', 'USA'],
  sdq: ['Las Americas Intl.', 'Santo Domingo', 'Dominican Republic'],
  sdr: ['Santander', 'Santander', 'Spain'],
  sdu: ['Santos Dumont', 'Rio de Janeiro', 'Brazil'],
  sea: ['Seattle-Tacoma Intl.', 'Seattle, WA', 'USA'],
  sez: ['Seychelles Intl.', 'Mahé', 'Seychelles'],
  sfo: ['San Francisco Intl.', 'San Francisco, CA', 'USA'],
  sgf: ['Springfield-Branson National', 'Springfield, MO', 'USA'],
  sgn: ['Tan Son Nhat Intl.', 'Ho Chi Minh City', 'Vietnam'],
  sgu: ['Saint George Municipal', 'Saint George, UT', 'USA'],
  shd: ['Shenandoah Valley', 'Staunton, VA', 'USA'],
  shr: ['Sheridan', 'Sheridan, WY', 'USA'],
  shv: ['Shreveport Regional', 'Shreveport, LA', 'USA'],
  sin: ['Changi Intl.', 'Singapore', 'Singapore'],
  sit: ['Sitka', 'Sitka, AK', 'USA'],
  sjc: ['Norman Y. Mineta San Jose Intl.', 'San Jose, CA', 'USA'],
  sjd: ['Los Cabos Intl.', 'San José del Cabo', 'Mexico'],
  sjj: ['Sarajevo Intl.', 'Sarajevo', 'Bosnia and Herzegovina'],
  sjo: ['Juan Santamaria Intl.', 'San José', 'Costa Rica'],
  sjt: ['San Angelo Regional', 'San Angelo, TX', 'USA'],
  sju: ['Luis Munoz Marin Intl.', 'San Juan', 'Puerto Rico'],
  skg: ['Thessaloniki Intl.', 'Thessaloniki', 'Greece'],
  slc: ['Salt Lake City Intl.', 'Salt Lake City, UT', 'USA'],
  sln: ['Salina Regional', 'Salina, KS', 'USA'],
  slp: ['Ponciano Arriaga Intl.', 'San Luis Potosi', 'Mexico'],
  smf: ['Sacramento Intl.', 'Sacramento, CA', 'USA'],
  smr: ['Simon Bolivar Intl.', 'Santa Marta', 'Colombia'],
  sna: ['John Wayne', 'Santa Ana, CA', 'USA'],
  snn: ['Shannon', 'Shannon', 'Ireland'],
  sof: ['Sofia', 'Sofia', 'Bulgaria'],
  spi: ['Capital', 'Springfield, IL', 'USA'],
  sps: [
    'Wichita Falls Municipal /Sheppard Air Force Base',
    'Wichita Falls, TX',
    'USA',
  ],
  srq: ['Sarasota Bradenton Intl.', 'Sarasota, FL', 'USA'],
  sti: ['Cibao Intl.', 'Santiago', 'Dominican Republic'],
  stl: ['Lambert-St. Louis Intl.', 'St. Louis, MO', 'USA'],
  str: ['Stuttgart', 'Stuttgart', 'Germany'],
  sts: ['Sonoma County', 'Santa Rosa, CA', 'USA'],
  stt: ['Cyril E. King', 'St. Thomas', 'US Virgin Islands'],
  stx: ['Henry E. Rohlsen', 'St. Croix', 'US Virgin Islands'],
  sun: ['Friedman Memorial', 'Sun Valley, ID', 'USA'],
  sux: ['Sioux Gateway', 'Sioux City, IA', 'USA'],
  svg: ['Stavanger Sola', 'Stavanger', 'Norway'],
  svo: ['Sheremetyevo Intl.', 'Moscow', 'Russia'],
  svq: ['Seville', 'Seville', 'Spain'],
  swo: ['Searcy Field', 'Stillwater, OK', 'USA'],
  sxm: ['Princess Juliana Intl.', 'St. Maarten', 'St. Maarten'],
  syd: ['Sydney (Kingsford Smith)', 'Sydney', 'Australia'],
  syr: ['Hancock Intl.', 'Syracuse, NY', 'USA'],
  szg: ['W. A. Mozart Salzburg', 'Salzburg', 'Austria'],
  tam: ['Tampico Intl.', 'Tampico', 'Mexico'],
  tap: ['Tapachula Intl.', 'Tapachula', 'Mexico'],
  tbn: ['Waynesville-St. Robert Regional', 'Fort Leonard Wood, MO', 'USA'],
  tbs: ['Tbilisi Intl.', 'Tbilisi', 'Georgia'],
  tbz: ['Tabriz', 'Tabriz', 'Iran'],
  ter: ['Lajes Field', 'Terceira', 'Portugal'],
  tfn: ['Tenerife North', 'Tenerife', 'Spain'],
  tfs: ['Tenerife South', 'Tenerife', 'Spain'],
  tgz: ['Angel Albino Corzo Intl.', 'Tuxtla Gutierrez', 'Mexico'],
  tia: ['Tirana Intl.', 'Tirana', 'Albania'],
  tij: ['Tijuana', 'Tijuana', 'Mexico'],
  tlh: ['Tallahassee Intl.', 'Tallahassee, FL', 'USA'],
  tll: ['Tallinn', 'Tallinn', 'Estonia'],
  tls: ['Toulouse-Blagnac', 'Toulouse', 'France'],
  tlv: ['Ben Gurion Intl.', 'Tel Aviv', 'Israel'],
  tol: ['Toledo Express', 'Toledo, OH', 'USA'],
  tpa: ['Tampa Intl.', 'Tampa, FL', 'USA'],
  tpe: ['Taiwan Taoyuan Intl.', 'Taipei', 'Taiwan'],
  trc: ['Francisco Sarabia Intl.', 'Torreon', 'Mexico'],
  trd: ['Trondheim Vaernes', 'Trondheim', 'Norway'],
  tri: ['Tri-Cities Regional', 'Bristol, TN', 'USA'],
  trn: ['Turin', 'Turin', 'Italy'],
  tru: ['Trujillo', 'Trujillo', 'Peru'],
  tul: ['Tulsa Intl.', 'Tulsa, OK', 'USA'],
  tun: ['Tunis-Carthage Intl.', 'Tunis', 'Tunisia'],
  tup: ['Tupelo Regional', 'Tupelo, MS', 'USA'],
  tus: ['Tucson Intl.', 'Tucson, AZ', 'USA'],
  tvc: ['Cherry Capital', 'Traverse City, MI', 'USA'],
  twf: ['Magic Valley Regional', 'Twin Falls, ID', 'USA'],
  txk: ['Texarkana Regional - Webb Field', 'Texarkana, TX', 'USA'],
  tyr: ['Tyler Pounds Regional', 'Tyler, TX', 'USA'],
  tys: ['McGhee Tyson', 'Knoxville, TN', 'USA'],
  uin: ['Quincy Municipal', 'Quincy, IL', 'USA'],
  uio: ['Mariscal Sucre Intl.', 'Quito', 'Ecuador'],
  uvf: ['Hewanorra', 'St. Lucia', 'St. Lucia'],
  vce: ['Venice Marco Polo', 'Venice', 'Italy'],
  vcp: ['Viracopos', 'Campinas', 'Brazil'],
  vct: ['County-Foster', 'Victoria, TX', 'USA'],
  vel: ['Vernal Regional', 'Vernal, UT', 'USA'],
  ver: ['Veracruz Intl.', 'Veracruz', 'Mexico'],
  vgo: ['Vigo', 'Vigo', 'Spain'],
  vie: ['Vienna Intl.', 'Vienna', 'Austria'],
  vko: ['Vnukovo', 'Moscow', 'Russia'],
  vlc: ['Valencia', 'Valencia', 'Spain'],
  vld: ['Valdosta Regional', 'Valdosta, GA', 'USA'],
  vno: ['Vilnius Intl.', 'Vilnius', 'Lithuania'],
  vps: ['Destin Fort Walton Beach', 'Destin, FL', 'USA'],
  vrn: ['Verona Villafranca', 'Verona', 'Italy'],
  vsa: ['Carlos Rovirosa Perez Intl.', 'Villahermosa', 'Mexico'],
  vup: ['Valledupar', 'Valledupar', 'Colombia'],
  waw: ['Frederic Chopin', 'Warsaw', 'Poland'],
  wro: ['Wroclaw', 'Wroclaw', 'Poland'],
  xna: ['Northwest Arkansas Regional', 'Fayetteville, AR', 'USA'],
  xry: ['Jerez', 'Jerez', 'Spain'],
  yeg: ['Edmonton Intl.', 'Edmonton', 'Canada'],
  yhz: ['Halifax Stanfield Intl.', 'Halifax', 'Canada'],
  ylw: ['Kelowna Intl.', 'Kelowna', 'Canada'],
  yqb: ['Quebec City Jean Lesage Intl.', 'Québec City', 'Canada'],
  yqr: ['Regina Intl.', 'Regina', 'Canada'],
  yul: ['Montreal-Pierre Elliott Trudeau Intl.', 'Montréal', 'Canada'],
  yum: ['Yuma Intl.', 'Yuma, AZ', 'USA'],
  yvr: ['Vancouver Intl.', 'Vancouver', 'Canada'],
  yxy: ['Whitehorse', 'Whitehorse', 'Canada'],
  yyc: ['Calgary Intl.', 'Calgary', 'Canada'],
  yyj: ['Victoria Intl.', 'Victoria', 'Canada'],
  yyz: ['Pearson Intl.', 'Toronto', 'Canada'],
  yzf: ['Yellowknife', 'Yellowknife', 'Canada'],
  zag: ['Franjo Tudman', 'Zagreb', 'Croatia'],
  zcl: ['La Calera', 'Zacatecas', 'Mexico'],
  zih: ['Ixtapa/Zihuatanejo Internacional', 'Ixtapa', 'Mexico'],
  zlo: ['Manzanillo', 'Manzanillo', 'Mexico'],
  zrh: ['Zurich', 'Zurich', 'Switzerland'],
}
