import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500">
        Guess the airport IATA code in 6 tries. After each guess, the color of
        the tiles will change to show how close your guess was to the IATA code.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="O" status="correct" />
        <Cell value="K" />
        <Cell value="C" />
      </div>
      <p className="text-sm text-gray-500">
        The letter O is in the IATA code and in the correct spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="B" />
        <Cell value="C" />
        <Cell value="N" status="present" />
      </div>
      <p className="text-sm text-gray-500">
        The letter N is in the IATA code but in the wrong spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="L" />
        <Cell value="A" status="absent" />
        <Cell value="X" />
      </div>
      <p className="text-sm text-gray-500">
        The letter A is not in the IATA code in any spot.
      </p>
    </BaseModal>
  )
}
